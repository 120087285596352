<template>
  <!-- <div style="width: 100%; height: 100%; position:absolute; top: 0; left: 0;">
    <div class="background-img" id="bg-te">
      <div class="loader">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <unity  src="/unity/Build/tedemo8.json" 
     
      :hideFooter="true"
      :external-progress="true"
      unityLoader="/unity/Build/UnityLoader.js"
      ref="unityInstance"
    ></unity>  -->

  <div style="width: 100%; height: 100%; position:absolute;">
    <div class="background-img" id="bg-se">
      <div class="loader">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <canvas id="unity-canvas" style="width: 1200px; height: 800px; background: #231F20" class="alturate"></canvas>

    

    <v-dialog
      v-model="dialogIsMobile"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Teaching tools does not work properly on mobiles
        </v-card-title>

        <v-card-text>
          You can continue to use, but we recommend using it on desktop
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogIsMobile = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card>
        <v-card-title class="text-h5">
          Deseas seguir?
        </v-card-title>
        <v-card-text>Si no selecciona nada, en un minuto aproximadamente saldra al Dashboard.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="confirmD(true)"
          >
            Si
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="confirmD(false)"
          >
            No
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Unity from 'vue-unity-webgl'
import { db } from '../../../plugins/firebase'
import JWTDecode from 'jwt-decode'
//import TimeMe from 'timeme.js'
// window.onbeforeunload = function (event) {
//   const xmlhttp=new XMLHttpRequest();
//   xmlhttp.open("POST","https://us-central1-leeleeadmin.cloudfunctions.net/timeGetTeacher", true);
//   xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//   xmlhttp.send('time=25');
// };
//var timeout;
export default {
  components: { Unity },
	name: "ActivitiesList",
  data() {
      return {
        link : '',
        dialogIsMobile:false,
        id: '',
        role: '',
        startTime: 0,
        interval:'',
        timeout: '',
        timeoutC: '',
        enableMove: true,
        dialogConfirm: false
      }
    },
    created(){     
       window.addEventListener('beforeunload', this.enviarDatosSalir)
      // window.addEventListener('beforeunload', () => {
      //       //I CAN ACCESS TO this VARIABLE
      //    console.log('salida')
      //     if (this.role != '' && this.id != '') {
      //       let date = new Date();
      //       let time = date.getTime();
      //       db.collection('TimeUse').doc(this.id).set({ [`${[[time]]}`]: 2 }, { merge: true })
      //     }
            
      //   }, false)
    },
    methods: {
      // async probarToken () {
      //   const token = await auth().currentUser.getIdToken();
      //   console.log(token)
      //   const decoded = JWTDecode(token);
      //   console.log(decoded)
      //   //console.log(Math.floor(Date.now()+ ( 3600 * 1000 * 24) / 1000))

      // },
      // async EnviarFirebase () {
      //   const token = await auth().currentUser.getIdToken();
      //   console.log(token)
      //   // const response = await fetch('https://us-central1-leeleeadmin.cloudfunctions.net/verifyTokenTE/'+token);
      //   // const myJson = await response.json(); //extract JSON from the http response
      //   // do something with myJson
      //   window.location.href = "leelee://game?token="+token;
      // },
      getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let answer = false;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          console.log("Windows Phone");
          answer=true
        }
        if (/Android/i.test(userAgent)) {
          console.log("Android");
          answer= true;
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          console.log("iOS");
          answer = true;
        }
        return answer;
      },
      enviarDatosSalir(){
        this.enableMove = false;
        clearTimeout(this.timeout);
        if (this.startTime != 0 && this.id != '') {
          let date = new Date();
          let ActualTime = date.getTime();
          let InitialTime = this.startTime.getTime()
          let seconds = Math.round((ActualTime - InitialTime) / 1000);

          const xmlhttp=new XMLHttpRequest();
          xmlhttp.open("POST","https://us-central1-leeleeadmin2022.cloudfunctions.net/timeGetTeacher", true);
          xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xmlhttp.send('time='+seconds+'&starttime='+InitialTime+'&id='+this.id);
        }
      },
      confirmD(opt){        
        if (opt){
          clearTimeout(this.timeoutC);
        }
        this.dialogConfirm=false;
      },
      tiempoInactividadSalir(){
        let me = this;
        this.timeout = setTimeout(function(){
          console.log("se acabo el tiempo de espera");
          let date = new Date();
          let time = date.getTime();
          me.dialogConfirm=true;
          me.timeoutC=setTimeout(function(){ 
            me.dialogConfirm=false;
            let seconds = (time - me.startTime.getTime()) / 1000;
            if (seconds > 10) {
              db.collection('TimeUse').doc(me.id).set({ [`${[[me.startTime.getTime()]]}`]: seconds }, { merge: true }).then(()=>{
                window.location.href = '/login';
              });
            }
          },60000);         
        },1200000);
      }
    },
    mounted() {
        // let responsiveScript = document.createElement('script')
        // responsiveScript.setAttribute('src', '/unity/TemplateData/responsive.javascript')
        // document.body.appendChild(responsiveScript)
        var elemento = document.querySelectorAll(".alturate");
        var height = window.innerHeight;
        var width = window.innerWidth;
        elemento[0].style.height=height+'px';
        elemento[0].style.width=width+'px';
        var unityGame; 
        let responsiveScript = document.createElement('script')
        responsiveScript.setAttribute('src', '/unity/Build/te06092023a.loader.js')
        document.body.appendChild(responsiveScript)
        responsiveScript.onload = () => {
            createUnityInstance(document.querySelector("#unity-canvas"), {
                dataUrl: "/unity/Build/te06092023a.data",
                frameworkUrl: "/unity/Build/te06092023a.framework.js",
                codeUrl: "/unity/Build/te06092023a.wasm",
                streamingAssetsUrl: "/unity/StreamingAssets",
                companyName: "escueladelectura",
                productName: "LeeLee TE",
                productVersion: "1.0.8",
            })
            .then((instance) =>
            {
                window.unityInstance = instance;      
                
                unityGame = instance;
                let me = this;
                var bg = document.getElementById('bg-se');
                if(bg != null){
                    bg.classList.add('d-none');
                }          
            })
            .catch((message) => { console.warn(message); });
        }
        let isMobile = this.getMobileOperatingSystem();
        if (isMobile) {
          this.dialogIsMobile = true;
        }
        this.id = this.$store.state.user.uid;
        let role = this.$store.state.user.role;
        if (role != 'IndividualTeacher') {
          role = 'SchoolTeacher';
        }
        this.role = role;
        let beginDate = new Date();
        this.startTime = beginDate;
        let seconds = 0;
        let me = this;
        let minutos = 15
        this.interval = setInterval(function() {
          seconds += (minutos*60)
          db.collection('TimeUse').doc(me.id).set({ [`${[[me.startTime.getTime()]]}`]: seconds }, { merge: true })
        }, ((minutos*60)*1000));
      

      document.onmousemove = function () {
        if(!me.enableMove) return;
        clearTimeout(me.timeout);
        console.log("movio el mouse");
        me.tiempoInactividadSalir();
      }
    },
    beforeDestroy(){
      //console.log('salio')
      clearInterval(this.interval);
      if (this.role != '' && this.id != '' && this.startTime != 0) {
        let date = new Date();
        let time = date.getTime();
        let seconds = (time - this.startTime.getTime()) / 1000;
        if (seconds > 10) {
          db.collection('TimeUse').doc(this.id).set({ [`${[[this.startTime.getTime()]]}`]: seconds }, { merge: true })
        }
      }
      this.enableMove = false;
      clearTimeout(this.timeout);
    },
    
    // beforeDestroy(){
    //   if (this.role != '' && this.id != '' && this.startTime != 0) {
    //     let date = new Date();
    //     let time = date.getTime();
    //     let seconds = (time - this.startTime.getTime()) / 1000;
    //     if (seconds > 10) {
    //       db.collection('TimeUse').doc(this.id).set({ [`${[[time]]}`]: seconds }, { merge: true })
    //     }
        
    //   }
    // }
}
</script>

<style scoped>
  @font-face {
    font-family: "Student";
    src: local("Student"),
    url('../../../assets/fonts/Little Student.otf') format("truetype");
  }
  /*.texto-entrada-te h2{
    font-family: "Student", Helvetica, Arial;
    font-size: 70px;
    padding-top: 20px;
    color: #3f7aff;
    text-shadow: 3px 3px 2px #b9b9b9;
    text-align: center;
  }
  .texto-entrada-footer-te h2{
    font-family: "Student", Helvetica, Arial;
    font-size: 70px;
    color: #3f7aff;
    text-shadow: 3px 3px 2px #b9b9b9;
    text-align: center;
  }
  @media screen and (min-width: 1400px) {
    .texto-entrada-te h2{
      font-size: 80px;
      padding-top: 80px;
      padding-bottom: 40px;
    }
    .texto-entrada-footer-te h2{
      font-size: 80px;
      padding-top: 60px;
    }
    .img-list img{
      height: 270px;
    }
  }
  .img-list img{
    cursor: pointer;
    margin: 0 -1px;
    height: 250px;
  }
  .tarjeta:hover{
    background-color: #03a9f4 !important;
  }
  .fondo-maestro-principal{
    background-image: url('../../../assets/admin/fondo-misiones.gif');
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
*/
  .enlace {
    font-size: 1.7em;
    font-family: cursive;
  }
  .download{
    width: 940px;
    font-size: 1.1em;
  }
  .background-img{
    width: 100%;
    height: calc(100% - 50px);
    background-image: url('/loadingimg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 45px;
    display: flex;
    z-index: 1;
  }
  .loader{
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
</style>